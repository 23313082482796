import React, { useEffect, useState } from "react"
import ReactHtmlParser from "react-html-parser"
import getImage from "../functions/getImage"
import Arrow from "../components/symbols/arrow"
import { Link } from "gatsby"
	
const IndexPage = ({item, i}) => {

	const [loadImageSection, setLoadImageSection] = useState(false)

	useEffect(() => {

	    if (typeof window !== "undefined") {
	      
	      setLoadImageSection(true)
	    }
	}, [])

	if(loadImageSection && item.node.casestudies.images){
	return(
  	
	<Link className="case-studies" to={`/case-studies/${item.node.slug}`} key={i}>
	          
	<div className="item">

	{item.node.casestudies.images.image1 &&
	  <div className="img" style={{ backgroundImage: `url("${getImage(item.node.casestudies.images.image1.mediaDetails.sizes)}")` }}></div>
	}

	  <div className="text">
	  <h2>{item.node.title}</h2>
	  {ReactHtmlParser(item.node.content)}
	  <div className="link">VIEW CASE STUDY

		 <Arrow />

	  </div>
	  </div>

	</div>

	</Link>
	)
	}
	else{

		return null
	}
}

export default IndexPage
