import * as React from "react"
import ReactHtmlParser from "react-html-parser"

const IndexPage = ({img, text, imgclass}) => (

  <div className={imgclass} style={{ backgroundImage: `url("${img}")` }}>
    
	<div>{ReactHtmlParser(text)}</div>    

  </div>
)

export default IndexPage
