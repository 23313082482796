import React, { useState, useEffect } from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import { useQuery } from "react-apollo"
import Layout from "../components/layout"
import SeoBasic from "../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import TextHeader from "../components/headers/textHeader"
import getImage from "../functions/getImage"
import BannerText from "../components/bannerText"
// import MailingList from "../components/mailingList"
import SEARCH_ITEMS_QUERY from "../hooks/search"
import Checkbox from "../components/html/checkbox"
// import ReactHtmlParser from "react-html-parser"
import checkboxes from "../data/checkboxes"
import CaseStudyItem from "../components/caseStudyItem"

export const query = graphql`
  query {
    wpPage(slug: {eq: "case-studies-landing"}) {
    title
    content
    casestudylanding {
      bannerText
      bannerImage {
        mediaDetails {
          sizes {
            name
            sourceUrl
            width
          }
        }
      }
    }
  }
  seoPage:wpPage(slug: {eq: "case-studies-landing"}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
  }
`

const IndexPage = ({
  data: {
    wpPage: { title, content, casestudylanding }, seoPage
  },
}) => {

  const [loadImageSection, setLoadImageSection] = useState(false)

  useEffect(() => {

      if (typeof window !== "undefined") {
        
        setLoadImageSection(true)
      }
  }, [])


  // Function to update the query with the new results
const updateQuery = (previousResult, { fetchMoreResult }) => {
  
  // return fetchMoreResult.cASESTUDIES.edges.length ? fetchMoreResult : previousResult;
  
  fetchMoreResult.cASESTUDIES.edges = [
      ...previousResult.cASESTUDIES.edges,
      ...fetchMoreResult.cASESTUDIES.edges,
    ]
  
  return fetchMoreResult

};

// The Search Results component.
const SearchResults = ({ showSearchResults, searchKey, searchCategory, toggleOrder }) => {

   let searchOrder
   if(toggleOrder){
    searchOrder="ASC"
   }
   else{
    searchOrder="DESC"
   }

   const str = String(searchKey)

  // Fetching the search data from WPGraphQL via Apollo.
  const { data: queriedData, fetchMore } = useQuery(SEARCH_ITEMS_QUERY, {
    variables: { first:10, after:null, searchTerm: searchKey, searchCategory: str, searchOrder: searchOrder},
    
  })

  

  return (

    <div
      className={`search-results-container ${showSearchResults ? 'show' : ''}`}
    >
      <div className="search-results">
      
        {!!searchKey &&

          queriedData?.cASESTUDIES?.edges &&
          queriedData.cASESTUDIES.edges.map((item, i) => (
            
            <CaseStudyItem item={item} i={i} key={i} />

          ))

        }

        {queriedData && queriedData.cASESTUDIES && queriedData.cASESTUDIES.edges && queriedData.cASESTUDIES.edges.length<1 &&

          <div>No results</div>

        }

      </div>

       <div className="buttonMore-holder">
        {!!searchKey && queriedData?.cASESTUDIES && queriedData.cASESTUDIES.pageInfo.hasNextPage &&
          <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      first: 5,
                      after: queriedData.cASESTUDIES.pageInfo.endCursor || null,
                      last: null,
                      before: null
                    },
                    updateQuery
                  });
                }}
              >
                VIEW MORE
              </button>
        }

        </div>

    </div>
  )
}


  const { bannerText, bannerImage } = casestudylanding
  // const banner_img = getImage(bannerImage.mediaDetails.sizes)
  const [showSearchResults, setShowSearchResults] = useState(true)
  const [searchKey, setSearchKey] = useState(' ')
  const [searchTextValue, setSearchTextValue] = useState('');
  const [toggleOrder, setToggleOrder] = useState(false)
  // const [searchOrder, setSearchOrder] = useState('ASC')
  const [modalDisplay, setModalDisplay] = useState(false)
  const [checkedItems, setCheckedItems] = useState({});
  const [searchCategory, setSearchCategory] = useState([]);
  
  const resetSearch = () => {

      const reset = ''

      setSearchTextValue('')
      setSearchKey(' ')
      setShowSearchResults(false)
      setSearchCategory([])
      setCheckedItems({})

  }

  const handleChange = (event) => {
      // updating an object instead of a Map
      setCheckedItems({...checkedItems, [event.target.name] : event.target.checked })

      // array for query
      if(!searchCategory.includes(event.target.name)){

        setSearchCategory([...searchCategory, event.target.name]) 
      }
      else{
        
        setSearchCategory(searchCategory.filter(item => item !== event.target.name));
      }

  }

  /* Place a start & end point to split array into 3 columns */
  const GetCheckbox = ({item, i, startItem, endItem}) => {

      if(i > startItem && i <= endItem ){
        return (
         <div className="checkbox" key={i}>
         <Checkbox name={item.name} checked={checkedItems[item.name]} onChange={handleChange} />
         <label key={item.key}>{item.name}</label>
         </div>
       )
      }
      else{

        return null
      }
                   
  }

  const ShowFilters = () => {

    return (

        <React.Fragment>

          <div className="filtersColumn">
          <h2>CONTEXT (type of project)</h2>

          {
              checkboxes.map((item, i) => (
                 
                  <GetCheckbox key={i} item={item} i={i} startItem={0} endItem={4} />
                 
              ))
          }

          </div>

          <div className="filtersColumn">
          <h2>SECTORS</h2>
           {
              checkboxes.map((item, i) => (
                 
                  <GetCheckbox key={i} item={item} i={i} startItem={4} endItem={10} />
                 
              ))
          }
          </div>

          <div className="filtersColumn">
          <h2>FINANCING (financing mechanism)</h2>
           {
              checkboxes.map((item, i) => (
                 
                  <GetCheckbox key={i} item={item} i={i} startItem={10} endItem={25} />
                 
              ))
          }
          </div>

        </React.Fragment>

    )

  }

  const SearchBox = ({boxName}) => {

    return (
      <div className={boxName}>
        
      <div  role="button" tabIndex={0}  onKeyDown={() => setSearchKey(searchTextValue)} onClick={() => setSearchKey(searchTextValue)} className="searchButton">
       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="Component_2">
        <rect id="Rectangle_176" data-name="Rectangle 176" width="16" height="16" fill="none"/>
        <path id="Path_99" data-name="Path 99" d="M15.9,14.5l-3.3-3.3A6.847,6.847,0,0,0,14,7,6.957,6.957,0,0,0,7,0,6.957,6.957,0,0,0,0,7a6.957,6.957,0,0,0,7,7,6.847,6.847,0,0,0,4.2-1.4l3.3,3.3ZM2,7A4.951,4.951,0,0,1,7,2a4.951,4.951,0,0,1,5,5,4.951,4.951,0,0,1-5,5A4.951,4.951,0,0,1,2,7Z" fill="#fff"/>
      </g>
      </svg>
      </div>

         <input type="text" autoFocus value={searchTextValue} aria-label={boxName} className={boxName} id={boxName} tabIndex={0}

            onChange={event => {

              setSearchTextValue(event.target.value)

            }}

          />
          
          <div className="searchButton" role="button" tabIndex={0} onKeyDown={() => resetSearch()} onClick={ () => resetSearch() }>
          <svg width="50" height="50" fill="#fff" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 70 70"><path class="cls-1" d="M950.73,548.1a.93.93,0,0,1-.75-.37,12.51,12.51,0,0,1-2.64-7.73A12.68,12.68,0,0,1,960,527.34a13.42,13.42,0,0,1,2.2.19.95.95,0,0,1-.33,1.87,11.61,11.61,0,0,0-1.87-.16,10.76,10.76,0,0,0-8.52,17.33,1,1,0,0,1-.17,1.33A1,1,0,0,1,950.73,548.1Z" transform="translate(-947.34 -522.5)"/><path class="cls-1" d="M960.28,529.28l-3.41,3.41a.92.92,0,0,0,.65,1.56.89.89,0,0,0,.64-.27l5-5a.94.94,0,0,0,0-1.3l-5-5a.91.91,0,0,0-1.29,1.29l3.41,3.41" transform="translate(-947.34 -522.5)"/><path class="cls-1" d="M959.72,550.72l3.41-3.41a.92.92,0,0,0-.65-1.56.89.89,0,0,0-.64.27l-5,5a.94.94,0,0,0,0,1.3l5,5a.91.91,0,0,0,1.29-1.29l-3.41-3.41" transform="translate(-947.34 -522.5)"/><path class="cls-1" d="M960,552.66a12.51,12.51,0,0,1-2.29-.21,1,1,0,0,1,.34-1.87,10.56,10.56,0,0,0,2,.18A10.78,10.78,0,0,0,970.76,540a10.63,10.63,0,0,0-2.37-6.73,1,1,0,0,1,1.49-1.19A12.66,12.66,0,0,1,960,552.66Z" transform="translate(-947.34 -522.5)"/></svg>
          </div>

      </div>
    )

  }

  return(
  <Layout>
     {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title={title} />
    }
    {loadImageSection &&
    <div style={{backgroundColor:"#2F2F2F"}}>
    
    <TextHeader title={title} content={content} />

    <div className="cs-searchcontrols">

      <SearchBox boxName="searchBox mobile" />

      <div className="button-holder">
      <div role="button" tabIndex={0} onKeyDown={() => setToggleOrder(!toggleOrder)} onClick={() => setToggleOrder(!toggleOrder)} className="searchButton sortBy">
        
        <div className="up">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
            <g id="Down_arrow_small_">
              <path id="Path_36" data-name="Path 36" d="M7.366,8.1,2,3.4,3.6,2,7.366,5.3,11.134,2l1.6,1.4Z" transform="translate(-3)" fill={toggleOrder ? "#000000" : "#FFFFFF"} />
              <rect id="Rectangle_1467" data-name="Rectangle 1467" width="10" height="10" transform="translate(0)" fill="none"/>
            </g>
          </svg>
        </div>

         <div className="down">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
            <g id="Down_arrow_small_">
              <path id="Path_36" data-name="Path 36" d="M7.366,8.1,2,3.4,3.6,2,7.366,5.3,11.134,2l1.6,1.4Z" transform="translate(-2)" fill={toggleOrder ? "#FFFFFF" : "#000000"} />
              <rect id="Rectangle_1467" data-name="Rectangle 1467" width="10" height="10" transform="translate(0)" fill="none"/>
            </g>
          </svg>
        </div>

      </div>
      <div className="label">Sort By</div>
      </div>

      <SearchBox boxName="searchBox" />

      {/*<div className="reset" role="button" tabIndex={0} onKeyDown={() => resetSearch()} onClick={ () => resetSearch() }>Reset</div>*/}

     {/* <div className="button-holder">
      <div className="label">Filter</div>
      <div role="button" tabIndex={0} onKeyDown={() => setModalDisplay(true)} onClick={ () => setModalDisplay(true) } className="searchButton">
       <svg xmlns="http://www.w3.org/2000/svg" width="13.584" height="13.801" viewBox="0 0 13.584 13.801">
        <path id="Path_201" data-name="Path 201" d="M30.33,465.63c1.913,0,3.826,0,5.74,0a1.965,1.965,0,0,1,.612.086.572.572,0,0,1,.38.8,2.765,2.765,0,0,1-.369.637q-2.1,2.828-4.217,5.645a1.122,1.122,0,0,0-.237.728c.012.99,0,1.98.007,2.97a1.087,1.087,0,0,1-.492.957q-1.309.911-2.623,1.816a.965.965,0,0,1-.254.132.309.309,0,0,1-.432-.224,1.325,1.325,0,0,1-.043-.377c0-1.788,0-3.577-.011-5.365a1.031,1.031,0,0,0-.187-.568c-1.4-1.9-2.815-3.78-4.222-5.67a3.122,3.122,0,0,1-.371-.584.623.623,0,0,1,.458-.937,2.053,2.053,0,0,1,.522-.05Q27.461,465.627,30.33,465.63Z" transform="translate(-23.53 -465.63)" fill="#fff"/>
      </svg>
      </div>
      </div>*/}

    </div>

    {modalDisplay &&
    <div className="modal-content">
            
      <div className="closeHolder">
      <div role="button" tabIndex={0} onKeyDown={() => setModalDisplay(false)} onClick={ () => setModalDisplay(false) } className="close">&times;</div>
      </div>
      
      <div className="contentHolder filters">

        <ShowFilters />

      </div>

    </div>
    }

    <div className="cs-imagesholder case-studies">

    {searchKey &&
    <SearchResults
        showSearchResults={showSearchResults}
        searchKey={searchKey}
        searchCategory={searchCategory}
        toggleOrder={toggleOrder}
    />
    }
    
    </div>

    <div className="imageText">
    {bannerImage.mediaDetails &&
    <BannerText img={getImage(bannerImage.mediaDetails.sizes)} text={bannerText} imgclass="bannerImg small" />
    }
    </div>

   {/* <div className="mailingList">
    <MailingList styles="mailingList-centre" />
    </div>*/}

    </div>
    }

    </Layout>
)
}

export default IndexPage
