import gql from "graphql-tag"

const SEARCH_ITEMS_QUERY = gql`
  query SearchQuery($searchTerm: String!, $searchCategory: String!, $searchOrder: OrderEnum!, $first: Int!, $after: String) {
    cASESTUDIES(first: $first, after: $after, where: {categoryName: $searchCategory, search: $searchTerm, orderby: {field: DATE, order: $searchOrder}}) {
      edges {
        node {
          title
          content
          slug
          casestudies {
          images{
            image1 {
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                  width
                }
              }
            }
          }
         }         
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`
export default SEARCH_ITEMS_QUERY

