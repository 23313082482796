const checkboxes = [
      {
          name: 'Natural Capital',
          key: 'Natural Capital',
          label: 'Natural Capital',
      },
      {
          name: 'Conservation Areas',
          key: 'Conservation Areas',
          label: 'Conservation Areas',
      },
      {
          name: 'Climate',
          key: 'Climate',
          label: 'Climate',
      },
      {
          name: 'Biodiversity',
          key: 'Biodiversity',
          label: 'Biodiversity',
      },
      {
          name: 'Funding Shortfall',
          key: 'Funding Shortfall',
          label: 'Funding Shortfall',
      },
      {
          name: 'Tourism',
          key: 'Tourism',
          label: 'Tourism',
      },
      {
          name: 'Agriculture',
          key: 'Agriculture',
          label: 'Agriculture',
      },
      {
          name: 'Livestock',
          key: 'Livestock',
          label: 'Livestock',
      },
      {
          name: 'Property',
          key: 'Property',
          label: 'Property',
      },
      {
          name: 'Residential',
          key: 'Residential',
          label: 'Residential',
      },
      {
          name: 'Energy',
          key: 'Energy',
          label: 'Energy',
      },
      {
          name: 'Debt',
          key: 'Debt',
          label: 'Debt',
      },
      {
          name: 'Equity',
          key: 'Equity',
          label: 'Equity',
      },
      {
          name: 'Mezzanine',
          key: 'Mezzanine',
          label: 'Mezzanine',
      },
      {
          name: 'Investment',
          key: 'Investment',
          label: 'Investment',
      },
      {
          name: 'De-risking',
          key: 'De-risking',
          label: 'De-risking',
      },
      {
          name: 'Aggregation',
          key: 'Aggregation',
          label: 'Aggregation',
      },
      {
          name: 'Scale',
          key: 'Scale',
          label: 'Scale',
      },
      {
          name: 'Outcome Based Financing',
          key: 'Outcome Based Financing',
          label: 'Outcome Based Financing',
      },
      {
          name: 'Payment for Success',
          key: 'Payment for Success',
          label: 'Payment for Success',
      },
      {
          name: 'Conservation Bonds',
          key: 'Conservation Bonds',
          label: 'Conservation Bonds',
      },
      {
          name: 'Biodiversity Offsets',
          key: 'Biodiversity Offsets',
          label: 'Biodiversity Offsets',
      },
      {
          name: 'Carbon Offsets',
          key: 'Carbon Offsets',
          label: 'Carbon Offsets',
      },
      {
          name: 'Debt for Nature',
          key: 'Debt for Nature',
          label: 'Debt for Natures',
      },
      {
          name: 'Financial Returns',
          key: 'Financial Returns',
          label: 'Financial Returns',
      }

  ];

  export default checkboxes