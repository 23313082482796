import * as React from "react"
import ReactHtmlParser from "react-html-parser"

const IndexPage = ({title, content}) => (
  <div className="textHeader">
    
    <h1>{title}</h1>
    <div>{ReactHtmlParser(content)}</div>

  </div>
)

export default IndexPage
